import api from './Api';
import UI from './UI';
import toast from './Toast';

export default class ProgramDetailsPage {
  constructor(pageState) {
    this.pageState = pageState;

    const splitUrl = document.location.pathname.split('/');
    this.programId = Number(splitUrl[splitUrl.length - 1]);

    // if the program is added to activity or agenda, we need to know the id to remove it
    this.activityId = null;
    this.agendaItemId = null;

    this.programButtonsContainer = document.getElementById('program-buttons-container');
    this.addToActivitiesBtn = document.getElementById('add-to-activities');
    this.removeFromActivitiesBtn = document.getElementById('remove-from-activities');
    this.addToAgendaBtn = document.getElementById('add-to-agenda');
    this.removeFromAgendaBtn = document.getElementById('remove-from-agenda');
    this.protectedInfContainer = document.getElementById('protected-information-wrapper');
  }

  renderProgramButtons() {
    if (this.pageState.currentUser) {
      this.protectedInfContainer.innerHTML = this.pageState.programProtectedContent;
      UI.show(this.protectedInfContainer);
    } else {
      this.protectedInfContainer.innerHTML = '';
    }

    /**
     * if buttons container element is missing, it means PHP code didn't include it in the view
     * at all (probably because the program is in the past)
     */
    if (!this.programButtonsContainer) return;

    // remove old button click event listeners
    this.addToActivitiesBtn.onclick = null;
    this.removeFromActivitiesBtn.onclick = null;
    this.addToAgendaBtn.onclick = null;
    this.removeFromAgendaBtn.onclick = null;

    // attach button click event listeners
    this.addToActivitiesBtn.onclick = () => {
      api
        .addProgramToActivities(this.programId)
        .then(res => {
          this.activityId = res.data.activity_id;
          this.renderProgramButtons();
        })
        .catch(console.error);
    };

    this.removeFromActivitiesBtn.onclick = () => {
      api
        .removeProgramFromActivities(this.activityId)
        .then(() => {
          this.activityId = null;
          this.renderProgramButtons();
        })
        .catch(console.error);
    };

    this.addToAgendaBtn.onclick = () => {
      api
        .addProgramToAgenda(this.programId)
        .then(() => {
          this.agendaItemId = this.programId;
          this.renderProgramButtons();
        })
        .catch(error => {
          if (error.response) {
            toast.error(error.response.data.message);
          }
        });
    };

    this.removeFromAgendaBtn.onclick = () => {
      api
        .removeProgramFromAgenda(this.programId)
        .then(() => {
          this.agendaItemId = null;
          this.activityId = null;
          this.renderProgramButtons();
        })
        .catch(console.error);
    };

    console.log(this.pageState.isCurrentUserApproved());

    const removeAllActionsBtns = () => {
      UI.hide(this.addToActivitiesBtn);
      UI.hide(this.addToAgendaBtn);
      UI.hide(this.removeFromActivitiesBtn);
      UI.hide(this.removeFromAgendaBtn);
      UI.hide(this.programButtonsContainer);
    };

    // decide which buttons should be visible
    if (this.pageState.currentUser && !this.pageState.isCurrentUserApproved()) {
      removeAllActionsBtns();
    } else if (this.pageState.currentUser) {
      if (this.agendaItemId) {
        UI.hide(this.addToAgendaBtn);
        UI.hide(this.addToActivitiesBtn);
        UI.hide(this.removeFromActivitiesBtn);
        UI.show(this.removeFromAgendaBtn);
        UI.show(this.programButtonsContainer);
      } else {
        UI.show(this.addToAgendaBtn);
        UI.hide(this.removeFromAgendaBtn);
        UI.show(this.programButtonsContainer);
        if (this.activityId) {
          UI.hide(this.addToActivitiesBtn);
          UI.show(this.removeFromActivitiesBtn);
        } else {
          UI.show(this.addToActivitiesBtn);
          UI.hide(this.removeFromActivitiesBtn);
        }
      }
    } else {
      removeAllActionsBtns();
    }
  }
}
