import React from 'react';
import { toast as toastify } from 'react-toastify';

const defaultToastTypeConfig = {
  success: { title: 'Success', icon: 'fas fa-check-circle' },
  error: { title: 'Error', icon: 'fas fa-exclamation-circle' },
  warning: { title: 'Warning', icon: 'fas fa-exclamation-circle' },
  info: { title: 'Info', icon: 'fas fa-info-circle' },
};

const ToastUI = ({ type, msg, title, icon }) => {
  const defaults = defaultToastTypeConfig[type];

  return (
    <div className="custom-tostify-content">
      <div className="icon-container">
        <i className={icon || defaults.icon}></i>
      </div>
      <div className="content-container">
        {title && <div className="content-title">{title}</div>}
        <div className="content-text">{msg}</div>
      </div>
    </div>
  );
};

class Toast {
  formatToastProps(msg) {
    if (typeof msg === 'string') {
      return { msg, title: '' };
    }

    if (typeof msg === 'object' && msg.hasOwnProperty('msg')) {
      return msg;
    }

    throw new Error('Invalid toast message format.');
  }

  success(msg) {
    const props = this.formatToastProps(msg);
    toastify.success(<ToastUI type="success" {...props} />);
  }

  error(msg) {
    const props = this.formatToastProps(msg);
    toastify.error(<ToastUI type="error" {...props} />);
  }

  warn(msg) {
    const props = this.formatToastProps(msg);
    toastify.warn(<ToastUI type="warning" {...props} />);
  }

  info(msg) {
    const props = this.formatToastProps(msg);
    toastify.info(<ToastUI type="info" {...props} />);
  }
}

const toast = new Toast();
export default toast;
