import api from './Api';
import UI from './UI';
import toast from './Toast';

export default class ProgramsPage {
  constructor(pageState) {
    this.programCards = document.querySelectorAll('[data-program-id]');
    this.addedPrograms = {
      activities: [],
      agenda_items: [],
    };
    this.pageState = pageState;
  }

  hideProgramButtons() {
    this.programCards.forEach(card => {
      const programFooter = card.querySelector('.session-block-card-footer');
      programFooter.remove();
    });
  }

  renderProgramButtons() {
    this.programCards.forEach(card => {
      const programId = Number(card.dataset.programId);
      const programFooter = card.querySelector('.session-block-card-footer');

      // select program card buttons
      const addToActivitiesBtn = programFooter.querySelector('[data-btn-id="add-to-activities"]');
      const removeFromActivitiesBtn = programFooter.querySelector(
        '[data-btn-id="remove-from-activities"]'
      );
      const addToAgendaBtn = programFooter.querySelector('[data-btn-id="add-to-agenda"]');
      const removeFromAgendaBtn = programFooter.querySelector('[data-btn-id="remove-from-agenda"]');

      /**
       * if any of the buttons are missing, it means PHP code didn't include them in the view
       * at all (probably because the program is in the past)
       */
      if (
        !addToActivitiesBtn ||
        !removeFromActivitiesBtn ||
        !addToAgendaBtn ||
        !removeFromActivitiesBtn
      ) {
        return;
      }

      // check if program has been added to agenda or activities already
      const activityProgram = this.addedPrograms.activities.find(i => i.program_id === programId);
      const agendaProgram = this.addedPrograms.agenda_items.find(i => i.program_id === programId);

      // remove old button click event listeners
      addToActivitiesBtn.onclick = null;
      removeFromActivitiesBtn.onclick = null;
      addToAgendaBtn.onclick = null;
      removeFromAgendaBtn.onclick = null;

      // attach button click event listeners
      addToActivitiesBtn.onclick = () => {
        api
          .addProgramToActivities(programId)
          .then(res => {
            this.addedPrograms.activities.push({
              program_id: programId,
              activity_id: res.data.activity_id,
            });

            this.renderProgramButtons();
          })
          .catch(console.error);
      };

      removeFromActivitiesBtn.onclick = () => {
        const targetProgram = this.addedPrograms.activities.find(i => i.program_id === programId);

        api
          .removeProgramFromActivities(targetProgram.activity_id)
          .then(() => {
            this.addedPrograms.activities = this.addedPrograms.activities.filter(
              i => i.program_id !== programId
            );

            this.renderProgramButtons();
          })
          .catch(console.error);
      };

      addToAgendaBtn.onclick = () => {
        api
          .addProgramToAgenda(programId)
          .then(() => {
            this.addedPrograms.agenda_items.push({ program_id: programId });
            this.renderProgramButtons();
          })
          .catch(error => {
            if (error.response) {
              toast.error(error.response.data.message);
            }
          });
      };

      removeFromAgendaBtn.onclick = () => {
        api
          .removeProgramFromAgenda(programId)
          .then(() => {
            this.addedPrograms.agenda_items = this.addedPrograms.agenda_items.filter(
              i => i.program_id !== programId
            );
            this.addedPrograms.activities = this.addedPrograms.activities.filter(
              i => i.program_id !== programId
            );
            this.renderProgramButtons();
          })
          .catch(console.error);
      };

      // decide which buttons should be visible
      if (this.pageState.currentUser && !this.pageState.isCurrentUserApproved()) {
        UI.hide(addToActivitiesBtn);
        UI.hide(addToAgendaBtn);
        UI.hide(removeFromActivitiesBtn);
        UI.hide(removeFromAgendaBtn);
        // UI.hide(programFooter);
      } else if (agendaProgram) {
        UI.hide(addToAgendaBtn);
        UI.hide(addToActivitiesBtn);
        UI.hide(removeFromActivitiesBtn);
        UI.show(removeFromAgendaBtn);
        UI.show(programFooter, 'flex');
      } else {
        UI.show(addToAgendaBtn);
        UI.hide(removeFromAgendaBtn);
        UI.show(programFooter, 'flex');
        if (activityProgram) {
          UI.hide(addToActivitiesBtn);
          UI.show(removeFromActivitiesBtn);
        } else {
          UI.show(addToActivitiesBtn);
          UI.hide(removeFromActivitiesBtn);
        }
      }
    });
  }
}
