require('./bootstrap');

import io from 'socket.io-client';
import ReactDOM from 'react-dom';
import React from 'react';
import { ToastContainer } from 'react-toastify';

import api from './public-pages/Api';
import toast from './public-pages/Toast';
import Navbar from './public-pages/Navbar';
import Sidebar from './public-pages/Sidebar';
import PageState from './public-pages/PageState';
import ProgramsPage from './public-pages/ProgramsPage';
import ProgramDetailsPage from './public-pages/ProgramDetailsPage';
import UI from './public-pages/UI';

ReactDOM.render(
  <div style={{ display: 'hidden', position: 'absolute' }}>
    <ToastContainer position="bottom-right" />
  </div>,
  document.getElementById('public-page-react-container')
);

const socketUrl = `${document.location.protocol}//${process.env.SOCKET_SERVER}`;

let socket;
let socketPublicRoom;
let programsPage;
let programDetailsPage;

const regWidget = document.querySelector('.section-register');
const regSideWidget = document.querySelector('.page-layout--side .register-widget');
const state = new PageState();
const navbar = new Navbar(state);
const sidebar = new Sidebar(state);

if (document.getElementById('protected-information-wrapper')) {
  state.programProtectedContent = document.getElementById(
    'protected-information-wrapper'
  ).innerHTML;
}

// check if path ends with '/programs' because if e.g. German is active language, path will be '/de/programs'
const isOnProgramsPage = document.location.pathname.endsWith('/programs');

if (isOnProgramsPage) {
  programsPage = new ProgramsPage(state);
}

const isOnProgramDetailsPage = document.location.pathname.search(/programs\/\d/) > 0;

if (isOnProgramDetailsPage) {
  programDetailsPage = new ProgramDetailsPage(state);
  programDetailsPage.renderProgramButtons();
}

const handleSidebarToggle = () => {
  state.isSidebarOpen = !state.isSidebarOpen;
  if (state.isSidebarOpen && navbar.isCollapsed()) {
    navbar.navbarToggler.click();
    document.querySelector('.hamburger--squeeze').classList.remove('is-active');
    state.isNavbarOpen = false;
  }

  sidebar.toggleSideBar();
};

const handleNavbarToggle = () => {
  const prevNavbarCollapsed = navbar.isCollapsed();
  if (state.isSidebarOpen && !prevNavbarCollapsed) {
    state.isSidebarOpen = false;
    sidebar.toggleSideBar();
  }
};

sidebar.setToggleListener(handleSidebarToggle);
navbar.addToggleListener(handleNavbarToggle);

const lngMenu = document.querySelector('.language-menu');
lngMenu?.querySelectorAll('.dropdown-item').forEach(lngOption => {
  const splitHref = lngOption.href.split('/');
  const lngLocale = splitHref[splitHref.length - 1];

  lngOption.addEventListener('click', () => {
    const changeLngApi = state.currentUser ? api.changeLanguageAuth : api.changeLanguageGuest;

    changeLngApi(lngLocale)
      .then(() => {
        let pathname = document.location.pathname;

        state.languages.forEach(lng => {
          if (pathname.startsWith(`/${lng.locale}`)) {
            pathname = pathname.replace(`/${lng.locale}`, '');
          }
        });

        window.open(pathname || '/', '_self');
      })
      .catch(console.error);
  });

  lngOption.removeAttribute('href');
});

const displayRegisterWidgets = () => {
  regSideWidget && UI.show(regSideWidget);
  regWidget && UI.show(regWidget);
};

const removeRegiserWidgets = () => {
  regSideWidget && UI.hide(regSideWidget);
  regWidget && UI.hide(regWidget);
};

const render = () => {
  navbar.renderNavbarRightContent();
  sidebar.render();
  navbar.renderNavbarLinks();
};

window.addEventListener('resize', () => {
  state.loggedMenuWidth = navbar.getMenuLoggedWidth();
  navbar.renderNavbarLinks();
});

const handleLogout = () => {
  // old behavior
  // state.currentUser = null;
  // socket?.disconnect();
  // render();
  // displayRegisterWidgets();
  // if (programsPage) programsPage.hideProgramButtons();

  // new behavior
  location.replace('/');
};

const logoutCurrentUser = () => {
  api
    .logout()
    .then(handleLogout)
    .catch(console.error);
};

api.setLogoutHandler(handleLogout);
navbar.setLogoutHandler(logoutCurrentUser);

const handleNewMessage = event => {
  toast.info({
    title: state.t('new_message_toast_title', 'You have a new message.'),
    msg: event.payload.content_string,
    icon: 'fas fa-envelope',
  });

  if (!state.currentUser.new_messages_exists) {
    state.currentUser.new_messages_exists = true;
    sidebar.renderUnreadMessagesIndicator();
  }
};

const handleSocketEvents = async event => {
  if (event.type === 'NEW_NOTIFICATION') {
    toast.info({
      title: state.t('new_notification_toast_title', 'You have a new notification'),
      msg: event.payload.subject,
      icon: 'fas fa-bell',
    });

    const notificationData = {
      id: event.payload.id,
      read_at: null,
      data: event,
    };

    try {
      // get the current timestamp in the correct format because it's not included in the event
      notificationData.created_at = await api.getCurrentTime();
    } catch (error) {
      // if the timestamp api fails for some reason, we still dispatch the notification but without 'created_at'
      notificationData.created_at = '';
    }

    navbar.addNotification(notificationData);
  } else if (event.type === 'NEW_CHAT_MESSAGE') {
    handleNewMessage(event);
  } else if (event.type === 'NEW_CHAT_ROOM') {
    // subscribe to new chat room events
    socket.on(event.payload.room, event => {
      if (event.type === 'NEW_CHAT_MESSAGE') {
        handleNewMessage(event);
      }
    });
    socket.emit('join', { room: event.payload.room });
  }
};

state.isLoading = true;
navbar.renderLoading();

api
  .refreshToken()
  .then(() => {
    api
      .getCurrentUserState()
      .then(currentUserData => {
        // set notifications
        if (currentUserData.notifications) {
          const { items, unread_count } = currentUserData.notifications;
          navbar.setNotifications(items.data, unread_count);
          delete currentUserData.notifications;
        }

        // set current user state
        state.currentUser = currentUserData;

        // connect the socket
        socket = io.connect(socketUrl);

        // subscribe to platform broadcast channel
        if (socketPublicRoom) {
          socket.on(socketPublicRoom, handleSocketEvents);
          socket.emit('join', { room: socketPublicRoom });
        }

        // subscribe to the personal channel
        socket.on(currentUserData.user.channel, handleSocketEvents);
        socket.emit('join', { room: currentUserData.user.channel });

        // subscribe to all chat rooms
        api
          .getMyChatRooms()
          .then(rooms => {
            rooms.forEach(room => {
              socket.on(room, event => {
                if (event.type === 'NEW_CHAT_MESSAGE') {
                  handleNewMessage(event);
                }
              });

              socket.emit('join', { room });
            });
          })
          .catch(console.error);

        if (programsPage) {
          programsPage.renderProgramButtons();
        }

        if (isOnProgramDetailsPage) {
          programDetailsPage.renderProgramButtons();
        }
      })
      .catch(console.error())
      .finally(() => {
        state.isLoading = false;
        render();
      });

    if (isOnProgramsPage) {
      api
        .getProgramsAddedToActivities()
        .then(res => {
          programsPage.addedPrograms = res.data;
          programsPage.renderProgramButtons();
        })
        .catch(console.error);
    }

    if (isOnProgramDetailsPage) {
      api
        .getSingleProgramActionRules(programDetailsPage.programId)
        .then(res => {
          programDetailsPage.activityId = res.data.activity_id;
          programDetailsPage.agendaItemId = res.data.agenda_item_id;
          programDetailsPage.renderProgramButtons();
        })
        .catch(console.error);
    }

    removeRegiserWidgets();
  })
  .catch(error => {
    console.error(error);
    state.isLoading = false;
    render();
    displayRegisterWidgets();
  });

api
  .getPlatformData()
  .then(({ modules, public_room, deadlines, languages, settings }) => {
    socketPublicRoom = public_room;
    socket?.on(socketPublicRoom, handleSocketEvents);
    state.enabledModules = modules;
    state.deadlines = deadlines;
    state.languages = languages;
    state.settings = settings;
    render();
  })
  .catch(console.error);

api
  .getAppTranslations()
  .then(translationsData => {
    state.translations = translationsData;
    render();
  })
  .catch(console.error);
