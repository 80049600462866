import DOMPurify from 'dompurify';

import api from './Api';
import UI from './UI';

export default class Navbar {
  constructor(pageState) {
    // navbar main container element
    this.navbar = document.querySelector('.navbar');

    // navbar right side content container
    this.navRightContainer = document.querySelector('#navbar-right-container');

    // dropdown toggler
    this.navbarToggler = document.querySelector('.navbar-toggler');

    // navbar logged in user dropdown elements
    this.userDropdown = document.querySelector('#header-user-menu');
    this.userDropdownMenu = document.querySelector('#dropdown-links-container');
    this.userDropdownToggle = document.querySelector('#header-dropdown-toggle');

    // navbar notifications dropdown
    this.notificationsDropdown = document.querySelector('#ntf-dropdown');
    this.notificationsDropdownToggle = document.querySelector('#ntf-dropdown-toggle');
    this.notificationsDropdownMenuInner = document.querySelector('#ntf-dropdown-menu-inner');
    this.notificationsDropdownMenuTitle = document.querySelector('#ntf-dropdown-title');
    this.notificationsDropdownMenuFooter = document.querySelector('#ntf-dropdown-footer');

    // navbar notifications modal
    this.notificationModal = $('#notification-modal');
    this.notificationModalTitle = $('#notification-modal-title');
    this.notificationModalBody = $('#notification-modal-body');

    // navbar not approved note
    this.noteContainer = document.querySelector('#not-approved-note-container');

    this.notificationsState = {
      items: [],
      unreadCount: 0,
    };

    this.registerLinkId = 'register-link';
    this.loginLinkId = 'login-link';

    this.pageState = pageState;
    const {
      t,
      isModuleEnabled,
      isOrganisationAdvanced,
      isOrganisationAdmin,
      isOrganisationSet,
      isCurrentUserApproved,
      isProfileHidden,
    } = pageState;

    this.userDropdownLinks = [
      {
        href: '/app/profile/edit',
        iconClass: 'far fa-user',
        text: () => t('nav_edit_profile_link', 'Edit Personal Profile'),
      },
      {
        href: '/app/organisation/edit',
        iconClass: 'far fa-building',
        text: () => t('nav_edit_org_link', 'Edit Organisation'),
        shouldRender: () => {
          if (isModuleEnabled('Organisation')) {
            const isOrgSet = isOrganisationSet();
            if (isOrganisationAdvanced()) {
              return isOrgSet && isOrganisationAdmin();
            }

            return isOrgSet;
          }

          return false;
        },
      },
      {
        href: '/app/organisation/create',
        iconClass: 'far fa-building',
        text: () => t('nav_create_org_link', 'Create Organisation'),
        shouldRender: () => {
          if (isModuleEnabled('Organisation')) {
            return !isOrganisationSet();
          }

          return false;
        },
      },
      {
        href: '/app/tickets',
        iconClass: 'far fa-ticket-alt',
        text: () => t('nav_tickets_link', 'My Tickets'),
        shouldRender: () => isCurrentUserApproved() && isModuleEnabled('Pretix'),
      },
      {
        href: '/app/settings',
        iconClass: 'far fa-sliders-h',
        text: () => t('nav_settings_link', 'Edit Settings'),
        shouldRender: () => isCurrentUserApproved(),
      },
      {
        href: '/app/meetings/settings',
        iconClass: 'fal fa-sliders-h',
        text: () => t('nav_meetings_settings_link', 'Meetings Settings'),
        shouldRender: () =>
          isCurrentUserApproved() && isModuleEnabled('Meeting') && !isProfileHidden(),
      },
      {
        href: '#',
        iconClass: 'far fa-sign-out',
        text: () => t('nav_logout_link', 'Log Out'),
      },
    ];
  }

  renderLoading() {
    UI.hide(this.navRightContainer);
  }

  setLogoutHandler(fn) {
    this.userDropdownLinks[this.userDropdownLinks.length - 1].onClick = fn;
    this.renderUserDropdownLinks();
  }

  setNotifications(notifications, unreadCount) {
    const { t } = this.pageState;

    // set notifications state
    this.notificationsState.items = notifications;
    this.notificationsState.unreadCount = unreadCount;

    // set notifications dropdown title
    this.notificationsDropdownMenuTitle.innerText = t('nav_notifications_title', 'Notifications');

    // set notifications dropdown footer link button text
    const btnLink = this.notificationsDropdownMenuFooter.children[0];
    btnLink.innerText = t('nav_view_all_notifications', 'View all notifications');

    this.renderNotifications();
  }

  addNotification(notificationData) {
    this.notificationsState.items = [notificationData, ...this.notificationsState.items];
    this.notificationsState.unreadCount++;
    this.renderNotifications();
  }

  openActiveNotificationModal(notificationData) {
    const { subject, content } = notificationData.data.payload;
    const sanitizedHTML = DOMPurify.sanitize(content, { ADD_ATTR: ['target'] });
    this.notificationModalTitle.text(subject);
    this.notificationModalBody.html(sanitizedHTML);
    this.notificationModal.modal('show');
  }

  renderNotifications() {
    if (this.pageState.currentUser && !this.pageState.isCurrentUserApproved()) {
      document.querySelector('#ntf-dropdown').classList.add('d-none');
    }

    if (!this.pageState.isModuleEnabled('Notification')) return;

    const notificationsContentId = 'notifications-body-content';
    const { items, unreadCount } = this.notificationsState;

    // if we have unread notifications, show the indicator on the notifications dropdown toggle
    const notificationsDropdownToggleLink = this.notificationsDropdownToggle.children[0];
    if (unreadCount > 0) {
      notificationsDropdownToggleLink.classList.add('unread');
    } else {
      notificationsDropdownToggleLink.classList.remove('unread');
    }

    // disable the 'view all notifications' button inside dropdown footer if notifications are emtpy
    const btnLink = this.notificationsDropdownMenuFooter.children[0];
    if (items.length === 0) {
      btnLink.classList.add('disabled');
    } else {
      btnLink.classList.remove('disabled');
    }

    // remove old notifications content if it exists
    this.notificationsDropdown?.querySelector(`#${notificationsContentId}`)?.remove();

    // set new notifications content
    let notificationsContent;

    if (!items || items.length === 0) {
      notificationsContent = UI.createNotificationsEmptyState();
      notificationsContent.id = notificationsContentId;
    } else {
      const notificationsListBody = document.createElement('div');
      notificationsListBody.id = notificationsContentId;
      notificationsListBody.setAttribute('class', 'dropdown-notifications-body');

      items.forEach(notification => {
        const notificationItem = UI.createNotificationItem(notification);
        notificationItem.addEventListener('click', () => {
          this.openActiveNotificationModal(notification);

          if (!notification.read_at) {
            api
              .markNotificationAsRead(notification.id)
              .then(() => {
                // update notifications state
                this.notificationsState.unreadCount -= 1;
                this.notificationsState.items = this.notificationsState.items.map(n => {
                  if (n.id === notification.id) n.read_at = true;
                  return n;
                });

                this.renderNotifications();
              })
              .catch(console.error);
          }
        });

        notificationsListBody.appendChild(notificationItem);
      });

      notificationsContent = notificationsListBody;
    }

    // insert the notifications content
    this.notificationsDropdownMenuInner.insertBefore(
      notificationsContent,
      this.notificationsDropdownMenuFooter
    );
  }

  makeNavbarVisible() {
    if (!this.navbar.classList.contains('visibility-visible')) {
      this.navbar.classList.add('visibility-visible');
    }
  }

  renderUserDropdownLinks() {
    if (!this.pageState.currentUser) return;

    this.userDropdownMenu.innerHTML = '';
    this.userDropdownLinks.forEach(linkData => {
      if (linkData.hasOwnProperty('shouldRender') && !linkData.shouldRender()) {
        return;
      }

      const link = UI.createUserDropdownLink(linkData);
      this.userDropdownMenu.appendChild(link);
    });
  }

  isCollapsed() {
    return document.querySelector('.navbar-collapse').matches('.show');
  }

  addToggleListener(cb) {
    this.navbarToggler.addEventListener('click', cb);
  }

  getMenuLoggedWidth() {
    return document.querySelector('.logged-in-menu').clientWidth;
  }

  renderNavbarLinks() {
    // TODO: refactor
    // navbar links datas
    const navbar = document.querySelector('.navbar-nav');
    const container = document.querySelector('#navbar .container').clientWidth;
    const menuBtn = document.querySelector('.user-menu-button')?.clientWidth || 0;
    const navBrand = document.querySelector('.navbar-brand')?.clientWidth || 0;
    const navBrandWhite = document.querySelector('.logo-white')?.clientWidth || 0;
    const navbarToggler = document.querySelector('.navbar-toggler').clientWidth;
    const wWidth = window.innerWidth;
    const navbarAllowedWidth =
      container -
      menuBtn -
      navBrand -
      navBrandWhite -
      navbarToggler -
      this.pageState.loggedMenuWidth;
    const threeDotsWidthWithoutPadding = 74;

    // render navbarlinks in dropdown if window width is more then 992, 992 is breakpoint
    if (wWidth > 992) {
      const navListItems = Array.from(
        document.querySelectorAll(
          '.navbar-nav:not(.logged-in-menu) .nav-item:not(.header-user-menu)'
        )
      );
      const navLinkItems = Array.from(
        document.querySelectorAll(
          '.navbar-nav:not(.logged-in-menu) .nav-item:not(.header-user-menu) .nav-link'
        )
      );
      // calculating full width of all links in left container
      let leftContainerAllLinksWidth = 74;
      Array.from(navLinkItems).map(item => (leftContainerAllLinksWidth += item.clientWidth));

      const numberOfAllowedLinks = Math.floor(
        (navbarAllowedWidth - threeDotsWidthWithoutPadding) /
          (leftContainerAllLinksWidth / navListItems.length)
      );
      const visibleLinksInNavbar = navListItems.slice(0, numberOfAllowedLinks);
      const dropdownLinks = navLinkItems.slice(numberOfAllowedLinks);

      // 74 is width od dropdown icon
      let widthOfVisibileLinks = 74;
      visibleLinksInNavbar.map(item => (widthOfVisibileLinks += item.clientWidth));

      const allowedSpaceWidth = navbarAllowedWidth - widthOfVisibileLinks;
      let firstDropdownLinkWidth;

      if (dropdownLinks.length > 0) {
        firstDropdownLinkWidth = dropdownLinks[0]?.clientWidth;
      }

      if (numberOfAllowedLinks <= navListItems.length) {
        // this is space between logged menu and visible links
        let moreLinks = 0;
        // if space more then 0, check if we have to put one more link in visible links
        if (allowedSpaceWidth > 0) {
          if (dropdownLinks.length > 1) {
            dropdownLinks.slice(1).map(item => {
              // we check if width of first item is  less then allowedSpaceWidth
              if (allowedSpaceWidth > firstDropdownLinkWidth) {
                moreLinks += 1;
                firstDropdownLinkWidth += item.clientWidth;
              }
            });
          } else {
            moreLinks = 1;
          }
        }
        navbar.innerHTML = '';
        const dropDownParent = document.createElement('li');
        dropDownParent.setAttribute('class', 'main-navlist-icon');
        const dropDownChild = document.createElement('span');
        dropDownChild.setAttribute('class', 'main-navlist-dropdown');

        navListItems
          .slice(0, numberOfAllowedLinks + moreLinks)
          .map(item => navbar.appendChild(item));
        // dont display dropdown when number of allowed and number of navlinks are equal
        if (numberOfAllowedLinks + moreLinks < navLinkItems.length) {
          navListItems
            .slice(numberOfAllowedLinks + moreLinks)
            .map(item => dropDownChild.appendChild(item));
          dropDownParent.appendChild(dropDownChild);
          navbar.appendChild(dropDownParent);
        }
      }
    }
  }

  removeAuthLinks() {
    document.getElementById(this.registerLinkId)?.remove();
    document.getElementById(this.loginLinkId)?.remove();
  }

  renderAuthLinks() {
    const { isModuleFeatureEnabled, deadlines, t } = this.pageState;
    if (!deadlines) return;
    const { registration, login } = deadlines;

    // hide the logged in dropdown menu
    UI.hide(this.userDropdown);
    UI.hide(this.notificationsDropdown);
    UI.hide(this.notificationsModal);

    // remove old links if they exist
    this.removeAuthLinks();

    // create the register link
    const registerLink = UI.createNavbarAuthLink({
      id: this.registerLinkId,
      btnClasses: 'btn btn-secondary text-nowrap register-button',
      btnText: t('nav_register_btn', 'Register'),
      href: '/app/register',
    });

    // create the login link
    const loginLink = UI.createNavbarAuthLink({
      id: this.loginLinkId,
      btnClasses: 'btn btn-primary text-nowrap register-button',
      btnText: t('nav_login_btn', 'Login'),
      href: '/app/login',
    });

    // display register and login links
    if (isModuleFeatureEnabled('Participant', 'registration') && registration.allow_page) {
      this.navRightContainer.appendChild(registerLink);
    }

    if (isModuleFeatureEnabled('Participant', 'login') && login.allow_page) {
      this.navRightContainer.appendChild(loginLink);
    }
  }

  renderUserDropdownToggle() {
    const { currentUser } = this.pageState;
    if (!currentUser) return;

    this.userDropdownToggle.innerHTML = '';
    const fallbackImgUrl = '/images/user/user-placeholder.png';

    const img = document.createElement('img');

    // load the fallback image if the original one fails to load
    img.addEventListener('error', () => {
      // if the fallback image cannot be loaded, don't try again
      if (!img.src.includes(fallbackImgUrl)) {
        img.src = fallbackImgUrl;
      }
    });

    img.src = currentUser.user.media[0]?.media_url.small;
    img.setAttribute('class', 'header-profile-image');
    this.userDropdownToggle.appendChild(img);
  }

  renderNotApprovedNote() {
    this.noteContainer.innerHTML = '';

    if (this.pageState.currentUser && !this.pageState.isCurrentUserApproved()) {
      this.noteContainer.setAttribute('class', 'not-authorized');
      const innerContainer = document.createElement('div');
      innerContainer.setAttribute('class', 'container');
      const div = document.createElement('div');
      const i = document.createElement('i');
      i.setAttribute('class', 'far fa-user-check mr-2');
      const text = document.createTextNode(
        this.pageState.t('nav_participant_not_approved_note', 'Your account is not approved yet.')
      );

      div.appendChild(i);
      div.appendChild(text);
      innerContainer.appendChild(div);
      this.noteContainer.appendChild(innerContainer);
    } else {
      this.noteContainer.innerHTML = '';
      this.noteContainer.classList.remove('not-authorized');
    }
  }

  renderNavbarRightContent() {
    if (this.pageState.isLoading) {
      this.renderLoading();
      return;
    }

    // show the navbar right container element if it was hidden previously while loading
    UI.show(this.navRightContainer, 'flex');

    if (this.pageState.currentUser) {
      this.removeAuthLinks();
      this.renderNotifications();
      UI.show(this.userDropdown);
      this.makeNavbarVisible();
      this.renderUserDropdownToggle();
      this.renderUserDropdownLinks();
    } else {
      this.makeNavbarVisible();
      this.renderAuthLinks();
    }
    this.renderNotApprovedNote();
    this.pageState.loggedMenuWidth = this.getMenuLoggedWidth();
  }
}
