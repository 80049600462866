export default class PageState {
  constructor() {
    this.isLoading = false;
    this.enabledModules = null;
    this.languages = null;
    this.deadlines = null;
    this.settings = null;
    this.currentUser = null;
    this.translations = null;
    this.isSidebarOpen = false;
    this.programProtectedContent = null;
    // deafult width of menu right content, we took max width and that's when user is logged out
    this.loggedMenuWidth = 330;

    this.t = this.t.bind(this);
    this.isModuleEnabled = this.isModuleEnabled.bind(this);
    this.isModuleFeatureEnabled = this.isModuleFeatureEnabled.bind(this);
    this.isOrganisationSet = this.isOrganisationSet.bind(this);
    this.isOrganisationAdmin = this.isOrganisationAdmin.bind(this);
    this.isOrganisationAdvanced = this.isOrganisationAdvanced.bind(this);
    this.isCurrentUserApproved = this.isCurrentUserApproved.bind(this);
    this.isProfileHidden = this.isProfileHidden.bind(this);
  }

  isModuleEnabled(moduleName) {
    return this.enabledModules?.hasOwnProperty(moduleName);
  }

  isModuleFeatureEnabled(moduleName, featureName) {
    return (
      this.isModuleEnabled(moduleName) &&
      this.enabledModules[moduleName] &&
      this.enabledModules[moduleName][featureName]
    );
  }

  t(key, fallback) {
    if (this.translations && this.translations[key]) {
      return this.translations[key];
    }

    return fallback;
  }

  isOrganisationSet() {
    return !!this.currentUser.organisation_profile?.id;
  }

  isOrganisationAdmin() {
    return !!this.currentUser.organisation_profile_user?.is_admin;
  }

  isOrganisationAdvanced() {
    return (
      this.isModuleEnabled('Organisation') &&
      this.isModuleFeatureEnabled('Organisation', 'advanced')
    );
  }

  isCurrentUserApproved() {
    if (!this.currentUser) return false;
    const { user } = this.currentUser;
    return user.hasOwnProperty('is_approved') ? user.is_approved : false;
  }

  isProfileHidden() {
    return this.currentUser?.is_hidden && this.settings.enable_hide_participants;
  }
}
